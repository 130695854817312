// Generated by Framer (b993cbf)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pHbE7Fonf"];

const variantClassNames = {pHbE7Fonf: "framer-v-nabpcm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; text?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "pHbE7Fonf", text: W1SDPigWk = "discover how we can help your business", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pHbE7Fonf", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sOMcW", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-nabpcm", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"pHbE7Fonf"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(255, 0, 122)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 0, 122)", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy03MDA=", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "24px", "--framer-text-alignment": "right", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-transform": "uppercase"}}>discover how we can help your business</motion.p></React.Fragment>} className={"framer-dqbjwh"} data-framer-name={"Button"} fonts={["GF;Poppins-700"]} layoutDependency={layoutDependency} layoutId={"I470:6480;488:18168"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={W1SDPigWk} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sOMcW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sOMcW .framer-x26s04 { display: block; }", ".framer-sOMcW .framer-nabpcm { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 16px 24px 16px 24px; position: relative; width: min-content; }", ".framer-sOMcW .framer-dqbjwh { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sOMcW .framer-nabpcm { gap: 0px; } .framer-sOMcW .framer-nabpcm > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-sOMcW .framer-nabpcm > :first-child { margin-top: 0px; } .framer-sOMcW .framer-nabpcm > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 412.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"W1SDPigWk":"text"}
 */
const FramerLySBZSphJ: React.ComponentType<Props> = withCSS(Component, css, "framer-sOMcW") as typeof Component;
export default FramerLySBZSphJ;

FramerLySBZSphJ.displayName = "Button";

FramerLySBZSphJ.defaultProps = {height: 56, width: 412.5};

addPropertyControls(FramerLySBZSphJ, {W1SDPigWk: {defaultValue: "discover how we can help your business", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerLySBZSphJ, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/LySBZSphJ:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf", weight: "700"}])